import { useEffect, useRef } from 'react'

const useGoogleAdsScript = () => {
	const injected = useRef(false)
	const scriptRef = useRef<HTMLScriptElement | null>(null)

	useEffect(() => {
		const googleAdsId = ENV.GOOGLE_ADS_ID
		if (
			!injected.current &&
			process.env.NODE_ENV === 'production' &&
			googleAdsId
		) {
			const script = document.createElement('script')
			script.async = true
			script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAdsId}`

			script.onload = () => {
				window.dataLayer = window.dataLayer || []
				function gtag(...args: any[]) {
					window.dataLayer.push(args)
				}
				window.gtag = gtag
				gtag('js', new Date())
				gtag('config', googleAdsId)
			}

			document.head.appendChild(script)
			scriptRef.current = script
			injected.current = true
		}

		return () => {
			if (scriptRef.current) {
				document.head.removeChild(scriptRef.current)
				scriptRef.current = null
			}
		}
	}, [])

	return null
}

export default useGoogleAdsScript
